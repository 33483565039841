export default [
  {
    header: 'ระบบ',
  },
  // {
  //   title: 'แจ้งปัญหา',
  //   route: 'richs-ticket',
  //   icon: 'MessageCircleIcon',
  // },
  // {
  //   title: 'วิธีใช้งาน',
  //   route: 'richs-manual',
  //   icon: 'HelpCircleIcon',
  // },
  {
    title: 'ตรวจสอบการใช้งาน',
    route: 'support-audit-admin',
    icon: 'SearchIcon',
  },
]
