export default [
  {
    header: 'บอทออโต้ฝากถอน',
  },
  {
    title: 'รายการฝาก',
    route: 'botauto-deposits',
    icon: 'DollarSignIcon',
    // tag: sessionStorage.getItem('stat_new_deposits') || '',
    badgeKey: 'stat_new_deposits',
    tagVariant: 'light-success',
  },
  {
    title: 'รายการถอน',
    route: 'botauto-withdraws',
    icon: 'MessageSquareIcon',
    // tag: sessionStorage.getItem('stat_new_withdraws') || '',
    badgeKey: 'stat_new_withdraws',
    tagVariant: 'light-danger',
  },
  // {
  //   title: 'รายการจับคู่ SMS - SCB',
  //   route: 'botauto-scb-matching',
  //   icon: 'ListIcon',
  //   // tag: sessionStorage.getItem('stat_new_withdraws') || '',
  //   badgeKey: 'stat_new_withdraws',
  //   tagVariant: 'light-danger',
  // },
  {
    title: 'รายการฝากถอนระบบ',
    route: 'botauto-statements',
    icon: 'ListIcon',
  },
  {
    title: 'รายการเดินบัญชี',
    route: 'botauto-bankstatement',
    icon: 'ListIcon',
  },
  {
    title: 'ข้อความ SMS',
    route: 'botauto-sms',
    icon: 'ListIcon',
  },
  {
    title: 'จัดการบัญชี',
    route: 'botauto-bankaccounts',
    icon: 'ListIcon',
  },
  {
    title: 'เติมด้วยสลิป',
    route: 'botauto-qr-deposits',
    icon: 'DollarSignIcon',
    // tag: sessionStorage.getItem('stat_new_deposits') || '',
    // badgeKey: 'stat_new_deposits',
    // tagVariant: 'light-success',
  },
]
