export default [
  {
    header: 'ข้อมูลลูกค้า',
  },
  {
    title: 'รายชื่อลูกค้า',
    route: 'member-list',
    icon: 'UsersIcon',
  },
  {
    title: 'รายการเคลื่อนไหวกระเป๋าเงิน',
    route: 'member-wallet-txns',
    icon: 'ListIcon',
  },
  {
    title: 'รายการเล่น',
    route: 'member-bet-txns',
    icon: 'BoxIcon',
  },
  {
    title: 'โปรโมชั่นลูกค้า',
    route: 'member-promotion',
    icon: 'GiftIcon',
  },
  {
    title: 'แนะนำเพื่อน',
    route: 'member-affiliate',
    icon: 'UserPlusIcon',
  },
  {
    title: 'จัดการเครดิต',
    route: 'member-credit',
    icon: 'DollarSignIcon',
  },
]
