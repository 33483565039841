export default [
  {
    title: 'สรุปภาพรวม',
    icon: 'HomeIcon',
    // tag: '2',
    // tagVariant: 'light-warning',
    children: [
      {
        title: 'สรุปรวมระบบ',
        route: 'dashboard',
      },
      // {
      //   title: 'Analytics',
      //   route: 'dashboard-analytics',
      // },
    ],
  },
]
